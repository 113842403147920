import React from 'react';
import { Carousel } from 'react-carousel-minimal';
function Part() {
    const data = [
       {
         image: "https://ik.imagekit.io/j6phbjneuop/New_Folder/10xgenomic_logo_ZqT7Sp-xd.png",

       },
       {
         image: "https://ik.imagekit.io/j6phbjneuop/New_Folder/output-onlinepngtools__1__ImSOEhdB9.png",
    
       },
       {
         image: "https://ik.imagekit.io/j6phbjneuop/New_Folder/Principal_Partner_Logos/isohelix_hJ-bOfNN9.png",
    
       },
       {
         image: "https://ik.imagekit.io/j6phbjneuop/New_Folder/output-onlinepngtools__3__chPN_mGLx.png",
      
       },
       {
         image: "https://ik.imagekit.io/j6phbjneuop/New_Folder/output-onlinepngtools__5__MIO1rPn9u.png",
 
       },
       {
        image: 'https://ik.imagekit.io/j6phbjneuop/New_Folder/Tanbead_bRGHWU2gY_unYDiY-4i.png',
    
    },
    {
      image: 'https://ik.imagekit.io/j6phbjneuop/New_Folder/Illumina__Inc.-Logo.wine__Va0ctbUB.svg',
    
    },
    {
      image: 'https://ik.imagekit.io/j6phbjneuop/New_Folder/Acea_FAUYz2ug8.png',
     
    },
    {
      image: 'https://ik.imagekit.io/j6phbjneuop/New_Folder/output-onlinepngtools__8__ep4blywVE.png',
   
    },
      
       
     ];
   
     const captionStyle = {
       fontSize: '2em',
       fontWeight: 'bold',
     }
     const slideNumberStyle = {
       fontSize: '20px',
       fontWeight: 'bold',
     }
     return (
       <div className="App">
         <div style={{ textAlign: "left" }}>
        

           <div style={{
             padding: "0 50px"
           }}>
             <Carousel
               data={data}
               time={2000}
               width="1250px"
               height="0px"
               captionStyle={captionStyle}
               radius="10px"
          
               slideNumberStyle={slideNumberStyle}
               captionPosition="bottom"
               automatic={true}
         
               pauseIconColor="white"
               pauseIconSize="40px"
            
               slideImageFit="cover"
               thumbnails={true}
               thumbnailWidth="200px"
               style={{
                 textAlign: "center",
                 maxWidth: "850px",
                 maxHeight: "500px",
                 margin: "40px auto",
               }}
             />
           </div>
         </div>
       </div>
     );
   }
   
   export default Part;