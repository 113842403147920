import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { HeroShaped, Map } from 'components/organisms';

const useStyles = makeStyles(() => ({
  map: {
    zIndex: 9,
  },
  icon: {
    background: 'transparent',
    borderRadius: 0,
  },
}));

const Contact = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <HeroShaped
        leftSide={
          <div>
            <SectionHeader
              title="Contact details"
              subtitle="Wether you have a question about the technology feature , pricing or a need of demo -our team of proficient people are always there to answer your questions. Drop a line today for the best consultative lifesciences solution"
              subtitleProps={{
                variant: 'body1',
                color: 'textPrimary',
              }}
              data-aos="fade-up"
              align="left"
            />
            <List disablePadding>
            <ListItem disableGutters data-aos="fade-up">
                <ListItemAvatar>
                  <Avatar
                    src="https://ik.imagekit.io/j6phbjneuop/calendar-and-clock-1737497-1478706_abtCnmh1d.png"
                    srcSet="https://ik.imagekit.io/j6phbjneuop/calendar-and-clock-1737497-1478706_abtCnmh1d.png"
                    className={classes.icon}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="Business Hours"
                  secondary="Mon to Fri - 9:00 am to 6:00 pm"
                  primaryTypographyProps={{
                    variant: 'subtitle1',
                    color: 'textSecondary',
                  }}
                  secondaryTypographyProps={{
                    variant: 'subtitle1',
                    color: 'textPrimary',
                  }}
                />
              </ListItem>
              <ListItem disableGutters data-aos="fade-up">
                <ListItemAvatar>
                  <Avatar
                    src="https://ik.imagekit.io/j6phbjneuop/540-5401844_blue-circle-phone-icon-clipart_l9E6_lL13.png"
                    srcSet="https://ik.imagekit.io/j6phbjneuop/540-5401844_blue-circle-phone-icon-clipart_l9E6_lL13.png"
                    className={classes.icon}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="Phone"
                  secondary="+91 1146170798"
                  primaryTypographyProps={{
                    variant: 'subtitle1',
                    color: 'textSecondary',
                  }}
                  secondaryTypographyProps={{
                    variant: 'subtitle1',
                    color: 'textPrimary',
                  }}
                />
              </ListItem>
              <ListItem disableGutters data-aos="fade-up">
                <ListItemAvatar>
                  <Avatar
                    src="https://ik.imagekit.io/j6phbjneuop/mail-2031009-1714869_Pu8GAK3aH.png"
                    srcSet="https://ik.imagekit.io/j6phbjneuop/mail-2031009-1714869_Pu8GAK3aH.png"
                    className={classes.icon}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="Email"
                  secondary="contact@premaslifesciences.com"
                  primaryTypographyProps={{
                    variant: 'subtitle1',
                    color: 'textSecondary',
                  }}
                  secondaryTypographyProps={{
                    variant: 'subtitle1',
                    color: 'textPrimary',
                  }}
                />
              </ListItem>
              <ListItem disableGutters data-aos="fade-up">
                <ListItemAvatar>
                  <Avatar
                    src="https://ik.imagekit.io/j6phbjneuop/map-command-comments-map-icon-11562897555gcydeuswdx__ZNigixeU.png"
                    srcSet="https://ik.imagekit.io/j6phbjneuop/map-command-comments-map-icon-11562897555gcydeuswdx__ZNigixeU.png"
                    className={classes.icon}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="PREAMAS LIFESCIENCES pvt ltd"
                  secondary="E-49/5, Second floor, okhla phase II , Delhi 110020"
                  primaryTypographyProps={{
                    variant: 'subtitle1',
                    color: 'textSecondary',
                  }}
                  secondaryTypographyProps={{
                    variant: 'subtitle1',
                    color: 'textPrimary',
                  }}
                />
              </ListItem>
            </List>
          </div>
        }
        rightSide={
          <Map
            center={[28.535763, 77.276433]}
            pins={data}
            className={classes.map}
          />
        }
      />
    </div>
  );
};

Contact.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Contact;
