import React  from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import emailjs from 'emailjs-com';
import {
  useMediaQuery,
  Grid,
  Typography,
  TextField,
  Button,
  Divider,
} from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { Section,} from 'components/organisms';

import { Contact } from 'views/ContactPageSidebarMap/components';
import { mapData } from 'views/ContactPageSidebarMap/data';
import Part from 'components/Part'



const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  
  section: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  },
  cover: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(-8),
      marginRight: theme.spacing(-8),
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '47vw',
      maxWidth: 740,
      height: '100%',
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 0,
    },
  },
  image: {
    width: '100%',
    height: 300,
    objectFit: 'cover',
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
      height: '100%',
    },
  },
  content: {
    flex: '0 0 100%',
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      flex: '0 0 50%',
      maxWidth: '50%',
    },
  },
  promoSection: {
    background: theme.palette.alternate.main,
    padding: theme.spacing(6, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(12, 2),
    },},
}));





const ContactPageCover = () => {
  function sendEmail(e){
    e.preventDefault();


    emailjs.sendForm('service_wbp4tq6', 'template_nmf49fq', e.target, 'user_cWibQO28lgt1qInNbXRs5').then(res =>{
        console.log(res);
    }).catch(err=> console.log(err));
    e.target.reset();
}


 


  
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <>
    <div className={classes.root}>
      <Section className={classes.section}>
        <div className={classes.wrapper}>
          <div className={classes.cover}>
            <Image
              src="https://onwhicar.sirv.com/premas/main/compressedoff.jpeg"
              alt="Contact"
              className={classes.image}
              lazyProps={{ width: '100%' }}
            />
          </div>
          <div className={classes.content}>
            <SectionHeader
              title="Contact us for anything"
              subtitle="Our goal is to be as helpful as possible."
              data-aos="fade-up"
              align="center"
            />
            <div>
            <form encType = 'multipart/form-data' method = 'post' onSubmit = {sendEmail}>
              <Grid container spacing={isMd ? 4 : 2}>
        
                <Grid item xs={12} data-aos="fade-up">
               
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                  >
                    Full name
                  </Typography>
                  <TextField

        
                    
                    placeholder="Your full name"
                 
          
                    variant="outlined"
                    size="medium"
                    name="fullname"
                    fullWidth
                    type="text"
                    required
                  />



{/* contact
                    organization */}
                </Grid>
                <Grid item xs={12} data-aos="fade-up">
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                  >
                    E-mail
                   
                  </Typography>
                  <TextField
                    placeholder="Your email address"
               
                    variant="outlined"
                    size="medium"
                    name="email"
                    fullWidth
                    type="Text"
                    required
                  />
                </Grid>
                <Grid item xs={12} data-aos="fade-up">
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                  >
                  organization
                   
                  </Typography>
                  <TextField
                    placeholder="Your comapny name"
                 
                    variant="outlined"
                    size="medium"
                    name="org"
                    fullWidth
                    type="text"
                    required
                  />
                </Grid>
                <Grid item xs={12} data-aos="fade-up">
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                  >
            Contact information
                   
                  </Typography>
                  <TextField
                    placeholder=" Your Phone Number"
                  
                    variant="outlined"
                    size="medium"
                    name="contact"
                    fullWidth
                    type="number"
                    required
                  />
                </Grid>
                <Grid item xs={12} data-aos="fade-up">
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                  >
                    Message
                  </Typography>
                  <TextField
                    placeholder="Your question about our services"
                    required
                   
                    variant="outlined"
                    name="msg"
                    fullWidth
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item container justify="center" xs={12}>
                  <Button
                
                    variant="contained"
                    type="submit"
                    color="primary"
                    size="large"
                    fullWidth
                   
                  >
                    submit
                  </Button>
                  
                </Grid>
                {/* <form method="post" action="https://getform.io/f/3ff67539-5b7c-4963-a4ab-a8861cdbbd83">
  ...
  <label>
    Email
    <input type="email" name="email" />
  </label>
  <label>
    Name
    <input type="text" name="name" />
  </label>
  <label>
    Message
    <input type="text" name="message" />
  </label>
  
  <button type="submit">Send</button>
  <input type="reset" value="Clear" />
</form> */}
   
              </Grid>
              
         </form>
            </div>
          </div>
        </div>
        
      </Section>
      <Divider />
    
    </div>
   
    <Contact data={mapData} />
   
   
   

    <section className = {classes.promoSection}>
    {/* <Part/> */}
    </section>
 
    </>
  );
};

export default ContactPageCover;
